import tw from 'tailwind-styled-components';

/*Fonts*/

export const H1 = tw.h1`
    text-5xl 
    leading-12 
    font-bold
    py-2
`;
export const H2 = tw.h2`
    text-4xl 
    leading-10 
    font-bold
    py-2
`;
export const H3 = tw.h3`
    text-base
     font-semibold leading-6 text-gray-900
`;
export const H4 = tw.h4`
    text-2xl
    leading-6
    font-bold
    py-2
`;
export const H5 = tw.h5`
    text-xl
    leading-5
    font-bold
    py-2
`;
export const H6 = tw.h6`
    text-base
    leading-4
    font-bold
    py-2
`;
export const P = tw.p`
    mt-1 max-w-2xl text-sm text-gray-500
`;
/*Container */

export const Container = tw.div`
    container 
    mx:auto
`;
/*Row Flex*/
export const RowFlex = tw.div`
    flex 
    justify-evenly 
    items-center
`;
export const ColumnFlex = tw.div`
    flex 
    flex-col
    justify-evenly 
    items-center
`;
/* Buttons */

export const IconButton = tw.button`
    inline-flex 
    items-center 
    rounded-full 
    border 
    border-transparent 
    bg-indigo-500 
    p-1 
    text-white 
    shadow-sm 
    hover:bg-indigo-600 
    focus:outline-none 
`;

export const RoundedButtonSmall = tw.button`
    rounded-full 
    inline-flex 
    items-center 
    m-2
    px-3 
    py-1.5 
    border 
    border-transparent 
    text-xs 
    rounded-full 
    shadow-sm 
    text-white 
    bg-indigo-600 
    hover:bg-indigo-700 
    focus:outline-none 
    focus:ring-2 
    focus:ring-offset-2 
    focus:ring-indigo-500
`;
export const RoundedButtonLarge = tw.button`
    rounded-full 
    bg-indigo-600 
    m-2
    py-2.5 
    px-4 
    text-sm 
    font-semibold 
    text-white 
    shadow-sm 
    hover:bg-indigo-500 
    focus:outline-none 
    focus:ring-2 
    focus:ring-offset-2 
    focus:ring-indigo-500
`;
export const PrimayButtonSmall = tw.button`
    inline-flex
    items-center
    gap-x-1.5
    rounded 
    bg-indigo-600 
    m-2
    py-2 
    px-6 
    text-sm 
    font-semibold 
    text-white 
    shadow-sm 
    hover:bg-indigo-500 
    focus:outline-none 
    focus:ring-2 
    focus:ring-offset-2 
    focus:ring-indigo-500
    ${(props) => props.width}
    disabled:bg-indigo-200
    disabled:cursor-not-allowed
`;
export const PrimaryButtonLarge = tw.button`
    rounded-md 
    bg-indigo-600 
    m-2
    py-2.5 
    px-4 
    text-sm 
    font-semibold 
    text-white 
    shadow-sm 
    hover:bg-indigo-500 
    focus:outline-none 
    focus:ring-2 
    focus:ring-offset-2 
    focus:ring-indigo-500 
    disabled:bg-indigo-200
    disabled:cursor-not-allowed
`;
export const SecondaryButtonSmall = tw.button`
    inline-flex
    items-center
    gap-x-1.5
    rounded 
    bg-white 
    m-2
    py-2 
    px-6 
    text-sm 
    font-semibold 
    text-gray-900 
    shadow-sm 
    ring-1 
    ring-inset 
    ring-gray-300 
    hover:bg-gray-50 
    shadow-sm 
    focus:outline-none 
    focus:ring-2 
    focus:ring-offset-3 
    focus:ring-gray-400
    disabled:bg-gray-50
    disabled:text-gray-300
    disabled:cursor-not-allowed
`;
export const SecondaryButtonLarge = tw.button`
    rounded-md 
    bg-white
    m-2  
    py-2.5 
    px-4 
    text-sm 
    font-semibold 
    text-gray-900 
    shadow-sm 
    ring-1 
    ring-inset 
    ring-gray-300 
    hover:bg-gray-50
    shadow-sm 
    focus:outline-none 
    focus:ring-2 
    focus:ring-offset-3 
    focus:ring-gray-400   
`;
export const AccentButtonSmall = tw.button`
    rounded 
    bg-indigo-100 
    border
    border-indigo-600
    m-2
    py-2 
    px-4 
    text-sm 
    font-semibold 
    text-indigo-600 
    shadow-sm 
    hover:bg-indigo-500 
    hover:text-white
    focus:outline-none 
    focus:ring-2 
    focus:ring-offset-2 
    focus:ring-indigo-500
    ${(props) => props.width}
`;

/* Links with button */

export const PrimaryLink = tw.button`
    inline-flex 
    items-center 
    gap-x-1.5 
    py-1.5 
    px-2.5 
    text-sm 
    cursor-pointer
    text-indigo-500 
    focus-visible:outline 
    focus-visible:outline-2 
    focus-visible:outline-offset-2 
    focus-visible:outline-indigo-600
`;
/* Badge*/

export const BadgeSmall = tw.span`
    inline-flex 
    items-center 
    rounded-full 
    bg-gray-100 
    m-2
    px-2.5 
    py-0.5 
    text-xs 
    font-medium 
    text-gray-800
`;
export const BadgeLarge = tw.span`
    inline-flex 
    items-center 
    rounded-full 
    bg-red-100 
    m-2
    px-3 
    py-1 
    text-sm 
    font-medium 
    text-red-800
`;
export const RoundedBadgeSmall = tw.span`
    inline-flex 
    items-center 
    rounded 
    bg-yellow-100
    m-2 
    px-2 
    py-0.5 
    text-xs 
    font-medium 
    text-yellow-800
`;
export const RoundedBadgeLarge = tw.span`
    inline-flex 
    items-center 
    rounded-md 
    bg-blue-100 
    m-2
    px-2.5 
    py-1
    text-sm 
    font-medium 
    text-blue-800
`;

/*Label*/

export const Label = tw.label`
    block 
    pt-2
    text-sm 
    font-medium 
    leading-6 
    text-gray-900
`;
/*Input box*/

export const Input = tw.input`
    block 
    w-full 
    rounded-md 
    border-0 
    px-2
    py-2
    text-gray-900 
    shadow-sm ring-1 
    ring-inset ring-gray-300 
    placeholder:text-gray-400 
    focus:ring-2 
    focus:ring-inset 
    focus:ring-gray-400 
    sm:text-sm 
    sm:leading-
    read-only:opacity-70
`;
/* Textarea*/

export const Textarea = tw.textarea`
    block 
    w-full 
    h-24
    rounded-md 
    border-0 
    text-gray-900 
    shadow-sm 
    ring-1 
    ring-inset 
    ring-gray-300 
    placeholder:text-gray-400 
    focus:ring-2 
    focus:ring-inset 
    focus:ring-indigo-600 
    sm:py-1.5 
    sm:text-sm 
    sm:leading-6
`;
/*Single select*/

export const SingleSelect = tw.select`
    block 
    w-full 
    rounded-md 
    border-0 
    py-1.5 
    pl-3 
    pr-10 
    text-gray-900 
    ring-1 
    ring-inset 
    ring-gray-300 
    focus:ring-2 
    focus:ring-indigo-600 
    sm:text-sm 
    sm:leading-6
`;
/*Toggle button*/

export const ToggleSwitch = tw.div`
    relative 
    inline-flex 
    h-6 
    w-11 
    flex-shrink-0 
    cursor-pointer 
    rounded-full 
    border-2 
    border-transparent 
    transition-colors 
    duration-200 
    ease-in-out 
    focus:outline-none 
    focus:ring-2 
    focus:ring-indigo-600 
    focus:ring-offset-2
    ${(props) => (props.enable ? 'bg-indigo-600' : 'bg-gray-200')}
`;
export const ToggleSpan = tw.span`
    pointer-events-none 
    inline-block 
    h-5 
    w-5 
    transform 
    rounded-full 
    bg-white 
    shadow 
    ring-0 
    transition 
    duration-200 
    ease-in-out
    ${(props) => (props.enable ? 'translate-x-5' : 'translate-x-0')}
`;
/*Avatar */

export const SmallAvatar = tw.span`
    inline-flex 
    h-10 
    w-10
    items-center 
    justify-center 
    rounded-full 
    bg-indigo-700
`;
export const MediumAvatar = tw.span`
    inline-flex 
    h-36 
    w-36 
    items-center 
    justify-center 
    rounded-full 
    bg-indigo-700
`;
export const SmallAvatarText = tw.span`
    text-md
    font-medium 
    leading-none 
    text-white
`;
export const MediumAvatarText = tw.span`
    text-6xl
    font-medium 
    leading-none 
    text-white
    align-center
`;
/*Table*/

export const Table = tw.table`
  min-w-full divide-y divide-gray-300
`;

export const TableHead = tw.thead`
  bg-gray-600
`;

export const TableBody = tw.tbody`
  divide-y 
  divide-gray-200 
  bg-white
`;

export const TableRow = tw.tr`
    items-center 
`;

export const TableHeader = tw.th`
  px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-600
`;

export const TableCell = tw.td`
  whitespace-nowrap px-3 py-4 text-sm text-gray-500
`;

/*Divider*/
export const Divider = tw.div`
    w-full 
    border-t 
    my-3 
    ${(props) => (props.divide ? 'border-gray-300' : 'border-gray-400')}
`;
// TODO: check direction column and span
export const Grid = tw.div`
grid
    ${(props) => props.gap} 
    ${(props) => props.column} 
    ${(props) => props.span}
    ${(props) => props.width}
    ${(props) => props.bgColor}
    ${(props) => props.height}
`;
export const ModuleGridTile = tw.a`
    p-6 
    rounded-lg 
    shadow 
    divide-y 
    sm:divide-y-0
    hover:bg-indigo-100
    bg-white
    cursor-pointer
`;
export const Datetime = tw.div`
    p-6 
    rounded-lg 
    shadow 
    divide-y 
    sm:divide-y-0
    hover:bg-indigo-100
    bg-white
    cursor-pointer
`;
