import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { getSpanClass, getLayoutColClass, getWidth } from '../utils/layoutHelpers';
import { Grid, PrimayButtonSmall, AccentButtonSmall } from './twEssential';
import { FILE_SIZE_ERROR_MESSAGE, REGION_GATEWAY } from '../utils/constants';
import { setLoading, setRestrictNavigation, setFragmentData } from '../features/screen/screenSlice';
import { sendRequest } from '../utils/apiCall';
import { triggerNotification } from '../features/screen/notificationSlice';
import { computePayload, getFragmentKey, transformFunction } from '../utils/helperFunctions';
import eventBus from '../services/EventBus';
import { ShouldRenderComponent } from './ShouldRenderComponent';
import Spinner from './Spinner';
import { JSONPath } from 'jsonpath-plus';

const Actions = (props) => {
  const { data, actions, params, onAssetUploadSuccess = () => {} } = props;
  const { service, sId, id, screen } = params;
  const navigate = useNavigate();
  const result = actions.filter((_action) => _action.id === data.id)?.[0];
  const goto = result?.goto;
  const fragmentKey = getFragmentKey(params);
  const fragmentData = useSelector((state) => state.screen.data[fragmentKey]);
  const bulkInstanceData = useSelector((state) => state.screen.bulkUploadInstances);
  const buttonDisable = useSelector((state) => state.screen.disableButton);
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);
  const [disableButton, setDisableButton] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  useEffect(() => {
    const handleEvent = (data) => {
      setDisableButton(data);
    };

    // Subscribe to the event
    eventBus.addEventListener('disableButton', handleEvent);

    // Clean up the subscription on component unmount
    return () => {
      eventBus.removeEventListener('refreshComponent', handleEvent);
    };
  }, []);

  function extractVariableAndReplaceValues(input) {
    let extractedString;
    if (input.startsWith('{{') && input.endsWith('}}')) {
      extractedString = input.substring(2, input.length - 2);
    } else {
      extractedString = input;
    }

    switch (extractedString) {
      case 'REGION_GATEWAY':
        return REGION_GATEWAY;
      case 'id':
        return id;
      case 'sId':
        return sId.split('-')[0];
      default:
        return fragmentData?.[extractedString] ? fragmentData?.[extractedString] : extractedString;
    }
  }

  const constructRequestURL = (url) => {
    let urlArray = [extractVariableAndReplaceValues(url.host)];
    urlArray = [
      ...urlArray,
      ...url?.path.map((_path) => {
        return extractVariableAndReplaceValues(_path);
      }),
    ];
    return `${urlArray.join('/')}`;
  };

  function constructBodyPayload(_payload) {
    let _bodyPayload = {};
    let _dataPayload = {};
    _.forEach(_payload, (value, key) => {
      if (typeof value === 'string') {
        _bodyPayload[key] = extractVariableAndReplaceValues(value);
      } else if (typeof value === 'object') {
        if (key === 'data') {
          _.forEach(value, (_value, _key) => {
            if (_key === '_allowedFieldsFromScreen') {
              _.forEach(_value, (_allowedValue) => {
                _dataPayload[_allowedValue] = fragmentData?.[_allowedValue];
              });
            }
          });
        }
      }
    });

    return { ..._bodyPayload, data: { ..._dataPayload } };
  }

  function formatPayloadString(_value, entityData) {
    let formattedString;
    let extractedValue = _value.split('.');
    if (extractedValue.length > 1) {
      let source = [];

      switch (extractedValue[0]) {
        case 'entityResponse':
          source = entityData;
          break;

        default:
          source = fragmentData?.[extractedValue[0]]
            ? fragmentData?.[extractedValue[0]]
            : fragmentData;
      }

      if (source[extractedValue[1]]) {
        formattedString = source[extractedValue[1]];
      }
    } else {
      if (fragmentData?.[_value]) {
        formattedString = fragmentData?.[_value];
      } else if (_value === 'bulkUploadInstances') {
        formattedString = bulkInstanceData;
      }
    }
    return formattedString;
  }
  function constructBodyPayloadV2(_payload, entityData) {
    let _bodyPayload = {};
    let _dataPayload = {};

    _.forEach(_payload, (value, key) => {
      if (typeof value === 'string') {
        _bodyPayload[key] = extractVariableAndReplaceValues(value);
      } else if (typeof value === 'object') {
        if (key === 'data') {
          _.forEach(value, (_value, _key) => {
            if (typeof _value === 'string') {
              let formattedValue = formatPayloadString(_value, entityData);
              if (formattedValue) {
                _dataPayload[_key] = formattedValue;
              }
            } else if (typeof _value === 'object') {
              if (_value.hasOwnProperty('computeFunction')) {
                _dataPayload[_key] = computePayload(_value, fragmentData, _key);
              } else if (_key === 'bulkUploadInstances') {
                _dataPayload[_key] = transformFunction(_value, bulkInstanceData, entityData);
              } else {
                let customPayload = {};
                _.forEach(_value, (data_value, data_key) => {
                  if (typeof data_value === 'string') {
                    let formattedCustomValue = formatPayloadString(data_value, entityData);
                    if (formattedCustomValue) {
                      customPayload[data_key] = formattedCustomValue;
                    }
                  } else if (typeof data_value === 'object') {
                    customPayload[data_key] = computePayload(data_value, fragmentData);
                  }
                });
                _dataPayload[_key] = customPayload;
              }
            }
          });
        }
      }
    });

    return { ..._bodyPayload, data: { ..._dataPayload } };
  }
  const actionType = async () => {
    if (goto.type === 'layout') {
      dispatch(setLoading(true));
      navigate(`/${goto.service}/${goto.sId}/${goto.screen}`);
    } else if (goto.type === 'goBack') {
      navigate(-1);
    } else if (goto.type === 'triggerAPI') {
      if (goto.action === 'insert') {
        try {
          const payload = fragmentData;
          // Need to filter payload based on the fields given in the triggerApi
          const { response } = await sendRequest({
            url: `${REGION_GATEWAY}/${service}/instances`,
            method: 'POST',
            body: {
              sId: sId,
              data: payload,
            },
          });

          if (response.status === 200) {
            if (goto.onSuccess) {
              goto['clearScreenData'] =
                goto.clearScreenData === false ? goto.clearScreenData : true;
              if (goto?.clearScreenData) {
                dispatch({ ...setFragmentData({}), fragmentKey: fragmentKey });
              }
              dispatch(setRestrictNavigation(false));
              //TODO : Roll Back
              navigate(`/${goto.onSuccess.service}/${goto.onSuccess.sId}/${goto.onSuccess.screen}`);
            }
            dispatch(triggerNotification(response.data.message, 'success'));
          } else {
            dispatch(triggerNotification(response.data.message, 'error'));
          }
        } catch (error) {}
      } else if (goto.action === 'createOrUpdateTicket') {
        try {
          const payload = fragmentData;
          // Need to filter payload based on the fields given in the triggerApi
          const { response, responseError } = await sendRequest({
            url: `${REGION_GATEWAY}/${service}/createOrUpdateTicket`,
            method: 'POST',
            body: payload,
          });

          if (response && response.status === 200) {
            if (goto.onSuccess) {
              goto['clearScreenData'] =
                goto.clearScreenData === false ? goto.clearScreenData : true;
              if (goto?.clearScreenData) {
                dispatch({ ...setFragmentData({}), fragmentKey: fragmentKey });
              }
              //TODO : Roll Back
              navigate(`/${goto.onSuccess.service}/${goto.onSuccess.sId}/${goto.onSuccess.screen}`);
            }
            dispatch(triggerNotification(response.data.message, 'success'));
          } else {
            dispatch(triggerNotification(responseError.message, 'error'));
          }
        } catch (error) {}
      } else if (goto.action === 'update' && id) {
        try {
          const payload = fragmentData;
          // Need to filter payload based on the fields given in the triggerApi
          const { response, responseError } = await sendRequest({
            url: `${REGION_GATEWAY}/${service}/instances/${sId}/${id}`,
            method: 'PUT',
            body: {
              data: payload,
            },
          });

          if (response && response.status === 200) {
            if (goto.onSuccess) {
              goto['clearScreenData'] =
                goto.clearScreenData === false ? goto.clearScreenData : true;
              if (goto?.clearScreenData) {
                dispatch({ ...setFragmentData({}), fragmentKey: fragmentKey });
              }
              //TODO : Roll Back
              navigate(`/${goto.onSuccess.service}/${goto.onSuccess.sId}/${goto.onSuccess.screen}`);
            }
            dispatch(setRestrictNavigation(false));
            dispatch(triggerNotification(response.data.message, 'success'));
          } else {
            dispatch(triggerNotification(responseError.message, 'error'));
          }
        } catch (error) {}
      } else if (goto.action === 'archive' && id) {
        try {
          const { response, responseError } = await sendRequest({
            url: `${REGION_GATEWAY}/${service}/archiveinstances/${sId}/${id}`,
            method: 'PATCH',
          });

          if (response && response.status === 200) {
            navigate(`/${goto.onSuccess.service}/${goto.onSuccess.sId}/${goto.onSuccess.screen}`);
          } else {
            dispatch(triggerNotification(responseError.message, 'error'));
          }
        } catch (error) {}
      }
    } else if (goto.type === 'request') {
      // Need to filter payload based on the fields given in the triggerApi
      const URL = constructRequestURL(goto.url);
      const bodyPayload = constructBodyPayload({ ...goto.body });
      const { response, responseError } = await sendRequest({
        url: URL,
        method: goto.method,
        body: bodyPayload,
      });

      if (response && response.status === 200) {
        if (goto.onSuccess?.length) {
          _.forEach(goto.onSuccess, (_successObj) => {
            if (_successObj.type === 'layout') {
              goto['clearScreenData'] =
                goto.clearScreenData === false ? goto.clearScreenData : true;
              if (goto?.clearScreenData) {
                dispatch({ ...setFragmentData({}), fragmentKey: fragmentKey });
              }
              navigate(`/${_successObj.service}/${_successObj.sId}/${_successObj.screen}`);
            } else if (_successObj.type === 'notification') {
              // F_TODO: Validate if setTimeout is the right way to fix the rendering issue
              dispatch(
                triggerNotification(
                  _successObj.message ? _successObj.message : 'Successful',
                  'success',
                ),
              );
            }
          });
        }
      } else {
        dispatch(triggerNotification(responseError.message, 'error'));
      }
    } else if (goto.type === 'requestV2') {
      triggerRequestV2(goto);
    } else if (goto.type === 'uploadFile') {
      hiddenFileInput.current.click();
    } else if (goto?.type === 'gotoEdit') {
      dispatch(setLoading(true));
      navigate(`/${goto?.service}/${goto?.sId}/${id}/${goto?.screen}`);
    } else if (goto?.type === 'navigate_next') {
      eventBus.emit('navigateNext');
    } else if (goto?.type === 'emitter') {
      /** STEP 1 : Emitting the data from popup */
      goto?.events?.forEach((_event) => {
        const resolvedPayload = {};
        for (const key in _event?.payload) {
          const value = _event?.payload[key];
          const result = JSONPath({ path: value, json: fragmentData });
          resolvedPayload[key] = result.length > 0 ? result[0] : undefined;
        }
        eventBus.emit(_event.name, resolvedPayload);
      });
      if (goto?.closePopupRef) {
        props.closePopup();
      }
    }
  };
  const triggerRequestV2 = async (actionItem, entityData) => {
    const URL = constructRequestURL(actionItem.url);
    let bodyPayload;
    if (actionItem.method === 'PUT') {
      bodyPayload = constructBodyPayload({ ...actionItem.body });
    } else if (actionItem.method === 'POST') {
      if (actionItem.body.data?._allowedFieldsFromScreen) {
        bodyPayload = constructBodyPayload({ ...actionItem.body });
      } else {
        bodyPayload = constructBodyPayloadV2({ ...actionItem.body }, entityData);
      }
    }
    if (result?.disableButton) {
      setDisableButton(true);
    }
    const { response, responseError } = await sendRequest({
      url: URL,
      method: actionItem.method,
      body: bodyPayload,
      responseType: actionItem.responseType,
    });

    if (response) {
      suceessHandler(actionItem.onSuccess, response.data.data);
    } else if (responseError) {
      dispatch(triggerNotification(responseError.data.message, 'error'));
    }
  };
  async function fetchTicketChildren(id) {
    const url = `${REGION_GATEWAY}/coreV2/instances/ticket/${id}`;
    try {
      let { response } = await sendRequest({
        url: url,
        method: 'GET',
      });
      if (response && response?.data?.data) {
        console.log(response.data.data);
        return response.data.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  const updateTicket = async (id, children) => {
    let result = await fetchTicketChildren(id);
    let childrenData = result?.children;
    const { response, responseError } = await sendRequest({
      url: `${REGION_GATEWAY}/coreV2/instances/ticket/${id}`,
      method: 'PUT',
      body: {
        data: {
          children: [...childrenData, children],
        },
      },
    });
    if (response) {
      console.log('Response');
      console.log(response);
    } else if (responseError) {
      console.log('responseError');
      console.log(responseError);
    }
  };

  const updateChildsTicket = async (childIds, payload, keyToUpdate) => {
    try {
      const { response, responseError } = await sendRequest({
        url: `${REGION_GATEWAY}/coreV2/updateParentFieldToChildren`,
        method: 'PUT',
        body: {
          data: {
            children: childIds,
            payload: payload,
            keyToUpdate,
          },
        },
      });
      if (response) {
        console.log('Response');
        console.log(response);
      } else if (responseError) {
        console.log('responseError');
        console.log(responseError);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function largestPosition() {
    const url = `${REGION_GATEWAY}/coreV2/instances/board_ticket?bucket=Open`;
    try {
      let { response } = await sendRequest({
        url: url,
        method: 'GET',
      });
      if (response && response?.data?.data) {
        const instances = response.data.data?.instances || [];
        const sortedInstances = instances.sort((a, b) => {
          return parseFloat(b.position) - parseFloat(a.position);
        });
        return sortedInstances?.[0]?.position || null;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  const updatePosition = async (ticketRef) => {
    try {
      let maxposition = await largestPosition();
      const { response, responseError } = await sendRequest({
        url: `${REGION_GATEWAY}/coreV2/instances/board_ticket/${ticketRef}`,
        method: 'PUT',
        body: {
          data: {
            position: (parseFloat(maxposition) + 1).toString(),
          },
        },
      });
      if (response) {
        console.log('Response');
        console.log(response);
      } else if (responseError) {
        console.log('responseError');
        console.log(responseError);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function suceessHandler(actions, entityResponse) {
    if (actions?.length) {
      _.forEach(actions, (_successObj) => {
        if (_successObj.type === 'layout') {
          goto['clearScreenData'] =
            _successObj.clearScreenData === false ? _successObj.clearScreenData : true;
          if (_successObj?.clearScreenData) {
            dispatch({ ...setFragmentData({}), fragmentKey: fragmentKey });
          }
          navigate(`/${_successObj.service}/${_successObj.sId}/${_successObj.screen}`);
        } else if (_successObj.type === 'notification') {
          dispatch(
            triggerNotification(
              _successObj.message ? _successObj.message : 'Successful',
              'success',
            ),
          );
        } else if (_successObj.type === 'updateParentChildren') {
          let workOrderId = entityResponse.parent;
          let children = entityResponse._id;
          if (workOrderId && children) {
            updateTicket(workOrderId, children);
          }
        } else if (_successObj.type === 'updateParentFieldToChildren') {
          let parentId = entityResponse?._id;
          let childIds = entityResponse?.children;
          let payload = entityResponse;
          if (parentId && childIds?.length) {
            updateChildsTicket(childIds, payload, _successObj?.keyToUpdate);
          }
        } else if (_successObj.type === 'updateTicketPosition') {
          let ticketRef = entityResponse?._id;
          if (ticketRef) {
            updatePosition(ticketRef);
          }
        } else if (_successObj.type === 'requestV2') {
          triggerRequestV2(_successObj, entityResponse);
        } else if (_successObj.type === 'closePopup') {
          props.closePopup();
        } else if (_successObj.type === 'closeSlidePopup') {
          dispatch({
            ...setFragmentData({
              ...fragmentData,
              sideNav: false,
            }),
            fragmentKey: getFragmentKey(_successObj.dataSource),
          });
        }

        // Set a timer to refresh the page after 1000ms
        if (_successObj.type === 'isReload') {
          setTimeout(() => {
            window.location.reload();
          }, 300);
        }
      });
    }
  }

  async function handleChange(event) {
    const file = event.target.files[0];
    await handleAssetSubmit(file);
    event.target.value = null;
  }

  const handleAssetSubmit = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    setLoadingSpinner(true);
    const { response, responseError } = await sendRequest({
      url: `${REGION_GATEWAY}/assetV2/upload`,
      method: 'POST',
      body: formData,
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.data &&
      response.data.data.id
    ) {
      onAssetUploadSuccess(response.data.data.id);
      setLoadingSpinner(false);
    } else {
      if (responseError?.code === 'ERR_NETWORK') {
        dispatch(triggerNotification(FILE_SIZE_ERROR_MESSAGE, 'error'));
        setLoadingSpinner(false);
      } else {
        dispatch(triggerNotification(response.data.errors, 'error'));
        setLoadingSpinner(false);
      }
    }
  };

  return (
    <>
      {loadingSpinner && (
        <Grid>
          <Spinner name={'Asset Uploading ...'} />
        </Grid>
      )}

      <Grid
        className={`grid ${
          data?.position === 'start' ? "'place-items-start" : 'place-items-center'
        }`}
        column={getLayoutColClass(data)}
        span={getSpanClass(data?.span)}
        width={getWidth(data?.width)}
      >
        {(() => {
          if (data?.buttonType === 'secondary' || goto?.buttonType === 'secondary') {
            return (
              <AccentButtonSmall onClick={actionType} width={getWidth(data?.width)}>
                {result?.title}
              </AccentButtonSmall>
            );
          } else {
            return (
              <ShouldRenderComponent visibilityCondition={result?.visibility?.conditions}>
                <PrimayButtonSmall
                  disabled={disableButton || buttonDisable}
                  onClick={actionType}
                  width={getWidth(data?.width)}
                >
                  {result?.title}
                </PrimayButtonSmall>
              </ShouldRenderComponent>
            );
          }
        })()}

        {(() => {
          if (goto?.type === 'uploadFile') {
            return (
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
              />
            );
          }

          return null;
        })()}
      </Grid>
    </>
  );
};

export default Actions;
